import styled from 'styled-components';

export const InfoContainer = styled.div`
/* background: #f0eeea; */
background: snow;
/* background: #f9f9f9; */
/* background: #f0ebea; //second color */
/* background: #efe6e8; //third color */
/* background: linear-gradient(
    108deg,
    rgb(240,238,234) 0%,
    rgb(239, 230, 232) 100%
); */
height:auto;
width: 100%;
/* background: rgb(240,238,234); */

@media screen and (max-width: 768px) {
padding: 70px 0;
margin-bottom: -80px;

}
`;

export const InfoWrapper = styled.div`
display: grid;
z-index: 1;
height:100vh;
width: 100%;
max-width: 1100px;
margin-right: auto;
margin-left: auto;
padding: 0 24px;
justify-content: center;
@media screen and (max-width: 768px) {
height: auto;

}
`;

export const InfoRow = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);

align-items: center;
grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

@media screen and (max-width: 768px) {
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
}
`

export const Column1 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col1;
`;

export const Column2 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col2;
`;

export const TextWrapper = styled.div`
max-width: 540px;
/* background: rgba(245,245,245, 0.3); */

padding-bottom: 60px;
padding-top: 0;
border-radius: 1px;
padding: 20px;


`;

export const TopLine = styled.p`
color: #282828;
font-size: 16px;
line-height: 16px;
font-weight: 700;
letter-spacing: 1.4px;
text-transform: uppercase;
margin-bottom: 16px;
`;

export const Heading = styled.h1`
margin-bottom: 24px;
font-size: 48px;
line-height: 1.1.%;
font-weight: 400;
letter-spacing: normal;
line-height: 68px;
text-transform: none;
color: #000000;

color: ${({lightText}) => (lightText ? '#000000' : '#01060')};

@media screen and (max-width: 480px) {
    font-size: 32px;
    line-height: 48px;
}
`;

export const Subtitle = styled.p`
max-width: 440px;
margin-bottom: 35px;
font-size: 16px;
color: ${({darkText}) => (darkText ? '#010606' : "#373f43")};
font-family: 'Roboto', sans-serif;
letter-spacing: normal;
line-height: 23px;
text-transform: none;
`

export const ButtonWrap = styled.div`
justify-content: center;
font-family: 'Playfair Display' serif;
width: 100%;
@media screen and (max-width: 480px) {
    padding: 0 24px;
}
`;

export const ImgWrap = styled.div`
max-width: 555px;
height:auto;
`;

export const Img = styled.img`
min-height: 355px; 
width: 100%;
margin: 0 0 10px 0;
border-radius: 2px;
object-fit: cover;
@media screen and (max-width: 960px) {
    /* padding-bottom: 80px; */
}


`;


