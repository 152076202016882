import React, { useState } from 'react';
import HeroSection from '../components/HeroSection'

import InfoSection from '../components/InfoSection';
import { about } from '../components/InfoSection/data';
import Footer from '../components/Footer';
import PriceList from '../components/PriceList';
import PriceListMobile from '../components/PriceListMobile';
import Team from '../components/Team';
import Contact from '../components/Contact';
import {contactData} from '../components/Contact/data';
import {animateScroll as scroll} from 'react-scroll';

window.onload = function () {
    scroll.scrollToTop();
  }

window.onload = () => {
    window.scrollTo(0,0)
}

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

         
const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    

    var img = new Image();
    var img2 = new Image();
    var img3 = new Image();
    var img4 = new Image();
    img.src="../images/ImageOne.jpg";
    img2.src="../images/ImageTwo.jpg";
    img3.src="../images/ImageThree.jpg";
    img4.src="../images/ImageFour.jpg";

    return (
        
        <>  
            <div id="project-container">
            <HeroSection />
            <InfoSection {...about} /> 
            <PriceList/>
            <PriceListMobile/>
            <Team/>
            <Contact {...contactData}/>
            </div>
            <Footer />

        </>
    )
}

export default Home
