import Aos from 'aos';
import React from 'react'
import  {CallButton} from '../ButtonElements';
import AOS from 'aos';
import 'aos/dist/aos.css';

import {InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, ButtonWrap, ImgWrap, Img
} from './InfoElements'


const InfoSection = ({ primary, lightBg, id, imgStart, topLine, headLine, description, alt, img }) => {

    AOS.init();

    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
             <InfoWrapper>
                 <InfoRow imgStart={imgStart}>
                     <Column1 data-aos="fade-left" data-aos-duration="1500">
                         <TextWrapper>
                          <TopLine> {topLine} </TopLine>
                          <Heading >{headLine}</Heading>
                          <Subtitle >{description}</Subtitle>
                          <ButtonWrap>
                              <CallButton href="tel:06114060057"
                              smooth={true}
                              duration={500}
                              spy={true}
                              exact="true"
                              offset={-80}
                              primary={primary ? 1 : 0}
                              > Termin vereinbaren
                              </CallButton>
                          </ButtonWrap>
                         </TextWrapper>
                     </Column1>
                     <Column2 data-aos="fade-right" data-aos-duration="1500">
                         <ImgWrap>
                             <Img src={img} alt={alt} />
                         </ImgWrap>
                     </Column2>
                 </InfoRow>
             </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default InfoSection
