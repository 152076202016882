export const contactData = {
    id: 'contact',
    contactsHeader: 'Kontakt',
    emailHeader:'Email',
    addressHeader: 'Adresse',
    phoneHeader: 'Telefon',
    companyEmail: 'info@haareszeiten-wiesbaden.de',
    companyPhone: '0611 - 4060057',
    companyAddress: 'Westenendstraße 17, 65197 Wiesbaden',
    name: 'Name *',
    date: 'Nachname *',
    phone: 'Telefonnummer *',
    mail: 'Email',
    message: 'Nachricht',
};