import React from 'react';
import {
 Nav,
 NavbarContainer,
 NavLogo, 
 MobileIcon,
 NavMenu, 
 NavItem,
 NavLinkRoute,
 NavLinkScroll
} from './NavbarElements';
import {animateScroll as scroll} from 'react-scroll';
import {VscListSelection} from 'react-icons/vsc';

const Navbar = ({toggle}) => {

const toggleHome = () => {
    scroll.scrollToTop();
};

    return (
        <>
        <Nav>
            <NavbarContainer>
                <NavLogo to='/' onClick={toggleHome}>HAARESZEITEN </NavLogo>
                <MobileIcon onClick = {toggle}>
                    <VscListSelection/>
                </MobileIcon>
            <NavMenu>
                <NavItem>
                    <NavLinkScroll
                     to = 'about'
                     smooth={true}
                     duration={500}
                     spy={true}
                     exact='true'
                     >
                      ÜBER UNS
                      </NavLinkScroll>
                </NavItem>

                <NavItem>
                    <NavLinkScroll
                     to = 'pricelist'
                     smooth={true}
                     duration={500}
                     spy={true}
                     exact='true'
                     >
                      ANGEBOT
                      </NavLinkScroll>
                </NavItem>

                <NavItem>
                    <NavLinkScroll
                     to = 'team'
                     smooth={true}
                     duration={500}
                     spy={true}
                     exact='true'
                     >
                      TEAM
                      </NavLinkScroll>
                </NavItem>

                <NavItem>
                    <NavLinkScroll
                     to = 'contact'
                     smooth={true}
                     duration={500}
                     spy={true}
                     exact='true'
                     >
                      KONTAKT
                      </NavLinkScroll>
                </NavItem>

            </NavMenu>
            </NavbarContainer>
        </Nav>
        </>

    );
};

export default Navbar;
