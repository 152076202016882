import styled from 'styled-components';

export const ContactsContainer = styled.div`
background: snow;
height: 100vh;
padding-top:120px;
max-width: 100% !important;



@media screen and (max-width: 786px) {
    padding-bottom: 50px;
    margin-top: -5px;
    height:auto;
}
`
export const ContactsWrapper = styled.div`
display: grid;
justify-content: center;
height: auto;
margin: 0 2%;
grid-template-columns: 1fr 1fr 1fr;
grid-template-areas: 'col1 col2 col2';

@media screen and (max-width: 786px) {
    height: auto;
    grid-template-columns: 1fr;
    grid-template-areas: 'col1' 'col2';
    padding-left:16px;
}
`
export const HeaderWrapper = styled.div`
border-bottom: 1px solid #282828;
    /* margin: 30px; */
    margin: 0 2%;
    margin-bottom: 30px;

@media screen and (max-width: 780px) {
    margin: 0px 30px 30px 30px;
};
`

export const CompanyWrapper = styled.div`
grid-area: col1;
padding: 16px;
/* height: 400px; */
`

export const FormWrapper = styled.form`
grid-area: col2;
padding: 16px;
/* height: auto; */
`

export const ContactsHeader = styled.p`
/* color: #282828;
font-size: 30px;
padding-bottom: 30px;
line-height: 28px;
text-align: right;
font-weight: 400;
letter-spacing: 1.4px;
text-transform: uppercase;
margin-bottom: 18px;
border-bottom: 1px solid #282828;
width: 100%; */

color: #282828;
  font-size: 30px;
  /* padding-bottom: 30px; */
  line-height: 28px;
  font-weight: 400;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 18px;
  width: 100%;

@media screen and (max-width: 780px) {
    line-height: 22px;
   
};
`

export const CompanyItem = styled.p`
font-size: 16px;
font-weight: 400;
font-family: 'Roboto', sans-serif;
padding-bottom: 18px;
color: #50504F;
@media screen and (max-width: 480px) {
    font-size: 14px;
    line-height: 20px;
    padding-bottom:8px;
};
`

export const CompanyHeader = styled.p`
font-size: 18px;
font-weight: 500;
line-height: 22px;
padding-bottom: 48px;
text-transform: uppercase;
color: #282828;
@media screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 24px;
};
`

export const FormHeader = styled.p`
margin-bottom: 12px;
font-size: 18px;
font-weight: 500;
line-height: 22px;
text-transform: uppercase;
color: #282828;

@media screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 22px;
};
`

export const FormItemWrapper = styled.div`
padding-bottom: 42px;

flex: 50%;

@media screen and (max-width: 480px) {
    flex:100%;
};
`


export const FormItemsWrapper = styled.div`
display:flex;
flex-wrap: wrap;


`

export const FormItem = styled.input`
font-size: 16px;
font-weight: 400;
font-family: 'Roboto', sans-serif;
/* padding-right: 32px; */
padding-bottom: 12px;
border-top: none;
border-left: none;
border-right:none;
color: #50504F;
border-bottom: 1px solid #c8c6c3;
background: snow;
width: 90%;
&:focus {
    border-bottom: 1px solid #282828;
    outline: none;
    transition: ease-in-out;
    box-shadow : 0.3s ease-in-out;
}

@media screen and (max-width: 480px) {
    font-size: 14px;
    line-height: 20px;
};
`

export const MessageArea = styled.textarea `
font-size: 16px;
font-weight: 400;
font-family: 'Roboto', sans-serif;
/* padding-right: 32px; */
padding-bottom: 12px;
width: 200px;
border-top: none;
border-left: none;
border-right:none;
border-bottom: 1px solid #c8c6c3;
background: snow;
width: 95%;
color: #50504F;

&:focus {
    border-bottom: 1px solid #282828;
    outline: none;
    transition: ease-in-out;

}

@media screen and (max-width: 480px) {
    font-size: 14px;
    line-height: 20px;
    width: 90%;
};
`

