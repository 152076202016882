import styled from 'styled-components';

export const FooterContainer = styled.div`
background-color: rgba(	1, 99, 121, 1);
max-width: 100% !important;
z-index:0;
position: sticky;
  bottom: 0;
  left: 0;


  /* padding-bottom: 4px; */
  /* padding-top:4px; */

`

export const FooterWrapper = styled.div`

justify-content: center;
align-items: center;
height: 100%;
display:grid;
grid-template-columns: 1fr 1fr;
grid-template-areas: 'col1 col2';

@media screen and (max-width: 786px) {
    height: auto;
    grid-template-columns: 1fr;
    grid-template-areas: 'col1' 'col2';
}
`

export const TimesWrapper = styled.div`
text-align: center;
grid-area: col1;
padding-top:80px;
height: 100%;
width: 100%;
padding-bottom:80px;
/* padding-top: 10px; */
@media screen and (max-width: 786px){
  padding-top:50px;
  height: 200px;
  
}

`

export const TimesTitle = styled.p`
font-size: 18px;
text-decoration: underline;
margin-bottom: 16px;
color: #282828;
`

export const TimesItem = styled.p`
font-size: 14px;
font-family: 'Roboto', sans-serif;
padding-bottom: 0.5rem;
color: #282828;

`

export const GoogleMap = styled.iframe`
height:100%;
width:100%;
`

export const MapWrapper = styled.div`
grid-area: col2;
position:relative;
width: 100%;
padding-bottom: 4px;
background-color: #282828;
height:100%;
@media screen and (max-width: 786px){
  height: 200px;
}
`




