import React from 'react';
import {FooterContainer, FooterWrapper, MapWrapper, GoogleMap, TimesWrapper, TimesTitle, TimesItem} 
from './FooterElements'

const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrapper>
                <TimesWrapper>
                <TimesTitle>ÖFFNUNGSZEITEN</TimesTitle>
                    <TimesItem>Montags Ruhetag</TimesItem>
                    <TimesItem>Di-Fr 10:00 - 19:00</TimesItem>
                    <TimesItem>Sa 08:00 - 14:00</TimesItem>
                </TimesWrapper>
           
            <MapWrapper>
                <GoogleMap src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.1965686396516!2d8.224243615718123!3d50.08260647942629!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bdbe722878761b%3A0x865167111e8024c0!2sHaareszeiten%20Sultan%20Yalciner!5e0!3m2!1sen!2sde!4v1628023168092!5m2!1sen!2sde"></GoogleMap>
                </MapWrapper> 
                </FooterWrapper>
        </FooterContainer>

    )
}

export default Footer
