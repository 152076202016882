import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom';
import {Link as LinkS} from 'react-scroll';

export const Nav = styled.nav`



background-color: rgba(	1, 99, 121, 0.9); 

height: 60px;
margin-top: -60px; 
display: flex;
justify-content: center;
align-items: center;
font-size: 1.1rem;
font-weight: 500;
position: sticky;
top: 0;
z-index: 100;

/* &:before {
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
    filter: blur(10px);
} */

@media screen and (max-width: 960px) {
    transition: all 0.8s  ease;
}
`;

export const NavbarContainer = styled.div`
display: flex;
justify-content: space-between;
z-index: 1;
width: 100%;
padding: 0 24px;
max-width: 1100px;
`;

export const NavLogo = styled(LinkR)`
color: #000000;
justify-self: flex-start;
cursor: pointer;
font-size: 1.5rem;
display: flex;
align-items: center;
margin-left: 24px;
text-decoration: none;
`;

export const MobileIcon = styled.div`
display:none;

@media screen and (max-width: 760px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.6rem;
    cursor: pointer;
    color: #000000;
}

&:hover {
    color: rgb(240,238,234);
}
`;

export const NavMenu = styled.ul`
display: flex;
align-items: center;
list-style: none;
text-align: center;
margin-right: -22px;

@media screen and (max-width: 760px) {
    display: none;
}
`;

export const NavItem = styled.li`
height: 30px;

&:hover {
    transform: scale(1.05);
    color: rgb(240,238,234);
    /* border-bottom: 1px solid #282828; */
}


`;

export const NavLinkScroll = styled(LinkS)`
color: #282828;
display: flex;
align-items: center;
text-decoration: none;
padding: 1rem;
height: 100%;
cursor: pointer;

&:hover {
    transform: scale(1.05);
    color: rgb(240,238,234);
}
`;

export const NavLinkRoute = styled(LinkR)`
color: #282828;
display: flex;
align-items: center;
text-decoration: none;
padding: 1rem;
height: 100%;
cursor: pointer;

&:hover {
    transform: scale(1.05);
    /* color: rgb(255, 245, 254); */
    /* color: #005f69; */
    /* color: #f0eeea; //smokey */
    color : rgb(240,238,234);
}
`;