import React from 'react'

import SultanContactImg from '../../images/Sultan.jpg'
import InesContactImg from '../../images/Ines2.jpg'
import AOS from 'aos';
import 'aos/dist/aos.css';


import './team.css';
const Team = () => {
    return (
        <>
        <div class="team-container" id="team">
        <div class="team-title-wrapper" data-aos="fade-left" data-aos-duration="1500">
        <p class="title">Team</p>
        </div>
            <div id="section6">
                <div class="grid col-5">
                    <div class="col test" data-aos="fade-right" data-aos-duration="1500"><img src={SultanContactImg} alt="sultan" />
                        <div class="testText">
                            <div class="inner">Inhaberin von Haareszeiten und Friseurmeisterin mit Leib und Seele. Seit bereits über 20 Jahren leitet Sie Ihren eigenen Salon in Wiesbaden und lässt dabei keine Kundenwünsche offen. </div>
                        </div>
                        <div class="testName">
                            <h4>Sultan <strong>Yalciner</strong></h4>
                            {/* <div className="mobile-subtext">
                                <h4>Inhaberin</h4>
                            </div> */}
                        </div>
                    </div>
                    <div class="col test" data-aos="fade-left" data-aos-duration="1500"><img src={InesContactImg} alt="ines"  />
                        <div class="testText">
                            <div class="inner">Langjährige Hair-Stylistin. Mit über 35 Jahren Berufserfahrung, weiß Sie Ihre Kunden durch einen passenden Haarschnitt stets zufrieden zu stellen.</div>
                        </div>
                        <div class="testName">
                            <h4>Ines <strong>Diemer</strong></h4>
                            {/* <div className="mobile-subtext">
                                <h4>Hairstylistin</h4>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default Team

// import './team.css';
// const Team = () => {
//     return (
//         <>
// <p class="main-title">Team</p>

// <div class="container">
//   <div class="content">
   
//       <div class="content-overlay"></div>
//       <img class="content-image" src={SultanContactImg}/>
//       <div class="content-details fadeIn-bottom">
//         <h3 class="content-title">This is a title</h3>
//         <p class="content-text">This is a short description</p>
//       </div>
//   </div>
// </div>

//         </>
//     )
// }

// export default Team
