import styled from 'styled-components'
import {Link as LinkS} from 'react-scroll'
import {Link as LinkR} from 'react-router-dom'
import { VscClose } from 'react-icons/vsc'

export const SidebarContainer = styled.aside`
position: fixed;
z-index: 999;
width: 100%;
height: 100%;
background-color: rgba(	1, 99, 121, 1);
display: grid;
align-items: center;
padding-top: 60px;
right: 0;
/* left: 0; */
transition: 0.3s ease-in-out;
/* top: 60px; */
/* opacity: .9; */
top: ${({ isOpen }) => (isOpen ? '-0%' : '-100%')};
`;

export const CloseIcon = styled(VscClose)`
color: #282828;
`;

export const Icon = styled.div`
position: absolute;
top: 1.2rem;
right: 1.5rem;
background: transparent;
font-size: 2rem;
cursor: pointer;
outline: none;
`;

export const SidebarWrapper = styled.div`
color: #000000;
`;

export const SidebarMenu = styled.ul`
display: grid;
grid-template-columns: 1fr;
grid-template-rows: repeat(6, 80px);
text-align: center;

@media screen and (max-width: 480px) {
    grid-template-rows: repeat(6, 60px);
}
`;

export const SidebarLink = styled(LinkS)`
display: flex;
align-items: center;
justify-content: center;
font-size: 1.5rem;
text-decoration: none;
list-style: none;
transition: 0.2s ease-in-out;
/* color: rgb(240,238,234); */
color: #282828;
cursor: pointer;
opacity: 1.0;
&:hover {
    color: snow;
    transition: 0.2s ease-in-out;
}
`;

export const SidebarLinkRoute = styled(LinkR)`
display: flex;
align-items: center;
justify-content: center;
font-size: 1.5rem;
text-decoration: none;
list-style: none;
transition: 0.2s ease-in-out;
/* color: rgb(240,238,234); */
color: #282828;
cursor: pointer;

&:hover {
    color: rgb(240,238,234);
    transition: 0.2s ease-in-out;
}
`;

export const SidebarRoute = styled(LinkR)`
border-radius: 50px;
background: #005f6a;
white-space: nowrap;
padding: 16px 64px;
color: #ffc9ce;
font-size: 16px;
outline: none;
border: none;
cursor: pointer;
transition: all 0.2s ease-in-out;
text-decoration: none;

&:hover {
    transition: all 0.2 ease-in-out;
    background: #fff;
    color: #ffc9ce;

}
`;
