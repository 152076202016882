import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './pricelistmobile.css';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     width: '100%',
//     backgroundColor: theme.palette.background.paper,
//   },

//   tabs: {
//     "& .MuiTab-root.Mui-selected": {
//       color: '#016379'
//     },
//   },

// }));

      
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'snow',
    width: '100%',
  },
  tabs: {

    "& .MuiTab-root.Mui-selected": {
      color: '#016379'
    },
  },
  
}));

export default function ScrollableTabsButtonAuto() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div class="pricelist-mobile-container" id="mobile-pricelist">
    <div class="pricelist-mobile-title-wrapper" data-aos="fade-left" data-aos-duration="1500">
<p class="price-mobile-title">Angebot</p>
</div>
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="#000000"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          data-aos="fade-right"
        data-aos-duration="1500"
        >
          <Tab label="HAARDESIGN" {...a11yProps(0)} />
          <Tab label="COLOURING" {...a11yProps(1)} />
          <Tab label="PFLEGE" {...a11yProps(2)} />
          <Tab label="HOCHZEITSSERVICE" {...a11yProps(3)} />
          <Tab label="KERATINGLÄTTUNG" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
      <div className="content-container" data-aos="fade-left" data-aos-duration="1500">
      <div className="itemlist-container">
       <p className="price-item-header">Damen</p>
       <p className="price-item">— Waschen, Föhnen</p>
       <p className="price-item">— Waschen, Schneiden, Föhnen</p>
       <p className="price-item">— Waschen, Schneiden, selber Föhnen</p>
       <p className="price-item">— Trockenhaarschnitt</p>
       <p className="price-item">— Hochsteckfrisuren</p>
       <p className="price-item-header">Herren</p>
       <p className="price-item">— Trockenhaarschnitt</p>
       <p className="price-item">— Waschen, Schneiden, Föhnen</p>
       <p className="price-item-header">Kinder</p>
       <p className="price-item">— Trocken bis 6 Jahre</p>
       <p className="price-item">— Trocken bis 14 Jahre</p>
       <p className="price-item">— Waschen, Schneiden, Föhnen</p>
       <p className="price-item-header">Dauerwelle</p>
       <p className="price-item">— Komplett-Dauerwelle</p>
       <p className="price-item">— Ab Kinnlänge</p>
       <p className="price-item">— Volumen-Dauerwelle</p>
       </div>
       <div className="pricelist-container">
       <p className="price-item-header"> &nbsp;</p>
        <p className="price-item"> 22,00€ </p>
        <p className="price-item"> 40,00€ </p>
        <p className="price-item"> 31,00€ </p>
        <p className="price-item"> Ab 21,00€ </p>
        <p className="price-item"> Ab 30,00€ </p>
        <p className="price-item-header"> &nbsp;</p>
        <p className="price-item"> 22,00€ </p>
        <p className="price-item"> 27,00€ </p>
        <p className="price-item-header"> &nbsp;</p>
        <p className="price-item"> 10,00€ </p>
        <p className="price-item"> 14,00€ </p>
        <p className="price-item"> 22,00€ </p>
        <p className="price-item-header"> &nbsp;</p>
        <p className="price-item"> Ab 45,00€ </p>
        <p className="price-item"> Ab 50,00€ </p>
        <p className="price-item"> Ab 30,00€ </p>
       </div>
       </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <div className="content-container" data-aos="fade-left" data-aos-duration="1000">
      <div className="itemlist-container">
       <p className="price-item-header">Damen</p>
       <p className="price-item">— Colourshines</p>
       <p className="price-item">— Intensivtönung</p>
       <p className="price-item">— Färbung</p>
       <p className="price-item">— Kammsträhnen</p>
       <p className="price-item">— Foliensträhnen</p>
       <p className="price-item-header">Herren</p>
       <p className="price-item">— Färbung</p>
       <p className="price-item-header">Unisex</p>
       <p className="price-item">— Augenbrauenfärbung</p>
       <p className="price-item">— Wimpernfärbung</p>
       </div>
       <div className="pricelist-container">
       <p className="price-item-header"> &nbsp;</p>
        <p className="price-item"> Ab 35,00€ </p>
        <p className="price-item"> Ab 30,00€ </p>
        <p className="price-item"> Ab 35,00€ </p>
        <p className="price-item"> Ab 35,00€ </p>
        <p className="price-item"> Ab 45,00€ </p>
        <p className="price-item-header"> &nbsp;</p>
        <p className="price-item"> Ab 20,00€ </p>
        <p className="price-item-header"> &nbsp;</p>
        <p className="price-item"> Ab 7,50€ </p>
        <p className="price-item"> Ab 9,50€ </p>

       </div>
       </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <div className="content-container" data-aos="fade-left" data-aos-duration="1000">
      <div className="itemlist-container">
      <p className="price-item-header">Haarpflege</p>
       <p className="price-item">— Haarkur</p>
       <p className="price-item">— Kopfmassage</p>
       <p className="price-item-header">Haarentfernung</p>
       <p className="price-item">— Augenbrauen zupfen mit Fadentechnik</p>
       <p className="price-item">— Oberlippe zupfen mit Fadentechnik</p>
       <p className="price-item">— Komplett Gesicht mit Fadentechnik</p>
       </div>
       <div className="pricelist-container">
       <p className="price-item-header"> &nbsp;</p>
        <p className="price-item"> Ab 9,00€ </p>
        <p className="price-item"> Ab 8,00€ </p>
        <p className="price-item-header"> &nbsp;</p>
        <p className="price-item"> 6,00€ </p>
        <p className="price-item"> 5,00€ </p>
        <p className="price-item"> Ab 10,00€ </p>
       </div>
       </div>
      </TabPanel>
      
      <TabPanel value={value} index={3}>
      <div className="content-container" data-aos="fade-left" data-aos-duration="1000">
      <div className="itemlist-container">
      <p className="price-item-header">Hochzeitsservice</p>
       <p className="price-item">Wünschen Sie sich für den schönsten Tag im Leben ein individuell auf Sie zugeschnittenes Umstyling? Gemeinsam erarbeiten wir für Sie den perfekten Look und bereiten Sie auf einen unvergesslichen Tag vor!</p>
       </div>
       <div className="pricelist-container">
       <p className="price-item-header"> &nbsp;</p>
        <p className="price-item"> Ab 150,00€ </p>
       </div>
       </div>
      </TabPanel>
      <TabPanel value={value} index={4}>
      <div className="content-container" data-aos="fade-left" data-aos-duration="1000">
      <div className="itemlist-container">
      <p className="price-item-header">Keratinbehandlung</p>
       <p className="price-item">Langes, glattes Haar – wer von uns wünscht sich das nicht? Mit Keratin ist es endlich möglich, auch die wildeste Mähne zu bändigen. Zwar ist das Ergebnis nicht dauerhaft, dennoch hält die Behandlung der Haare über einen längeren Zeitraum für etwa drei bis sechs Monate.</p>
       <p className="price-item">Bei einer Keratin Glättung handelt es sich um einen chemischen Prozess, der die Haare langanhaltend glatt werden lässt. Durch den chemischen Vorgang der Keratin Behandlung wird die Oberflächenstruktur jedes einzelnen Haars verschlossen, sodass das Haar auch nach mehreren Haarwäschen glatt bleibt.</p>
       <p className="price-item">Je nach Haarlänge und -struktur sollten Sie für die Keratin-Behandlung zwischen 3 bis 5 Stunden einplanen.</p>
       </div>
       <div className="pricelist-container">
       <p className="price-item-header"> &nbsp;</p>
        <p className="price-item"> Preis auf Anfrage </p>
       </div>
       </div>
      </TabPanel>
    </div>
    </div>
  );
}