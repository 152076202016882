import React from 'react'
import { ContactsContainer, ButtonWrapper, FormItemsWrapper, FormItemWrapper, MessageArea, HeaderWrapper, ContactsWrapper,CompanyWrapper,FormWrapper, CompanyHeader, ContactsHeader, CompanyItem, FormItem, FormHeader} from './ContactElements'
import { Button } from '../ButtonElements';
import emailjs from 'emailjs-com';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Contact = ({id, message, contactsHeader, emailHeader, addressHeader, phoneHeader, companyEmail, companyAddress, companyPhone, name, date, phone, mail}) => {
    
    function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('service_kio6p0n', 'template_lbkd0en', e.target, 'user_XTYM0kjOZzL8nchrSlWMS')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });

          e.target.reset();
      }
    
    return (
        <>
            <ContactsContainer id={id}>
            <HeaderWrapper data-aos="fade-left" data-aos-duration="1500">
                <ContactsHeader>{contactsHeader}</ContactsHeader>
                </HeaderWrapper>
                <ContactsWrapper>
                    <CompanyWrapper data-aos="fade-right" data-aos-duration="1500">
                        <CompanyItem>{companyEmail}</CompanyItem>
                            <CompanyHeader>{emailHeader}</CompanyHeader>
                            <a href="https://www.google.com/maps?ll=50.082607,8.226432&z=15&t=m&hl=en&gl=DE&mapclient=embed&cid=9678630397419726016" style={{textDecoration: 'none'}}>
                        <CompanyItem>{companyAddress}</CompanyItem>
                        </a>
                            <CompanyHeader>{addressHeader}</CompanyHeader>
                        <CompanyItem>{companyPhone}</CompanyItem>
                            <CompanyHeader>{phoneHeader}</CompanyHeader>
                    </CompanyWrapper>
                    <FormWrapper onSubmit={sendEmail} data-aos="fade-left" data-aos-duration="1500">
                    <FormItemsWrapper>
                    <FormItemWrapper>
                        <FormHeader>{name}</FormHeader>
                            <FormItem placeholder='Ihr Vorname' name="from_surname"/>
                            </FormItemWrapper>
                            <FormItemWrapper>
                        <FormHeader>{date}</FormHeader>
                            <FormItem placeholder='Ihr Nachname' name="from_name"/>
                            </FormItemWrapper>
                            <FormItemWrapper>
                        <FormHeader>{phone}</FormHeader>
                            <FormItem placeholder='Ihre Telefonnummer' name="phone_number"/>
                            </FormItemWrapper>
                            <FormItemWrapper>
                        <FormHeader>{mail}</FormHeader>
                            <FormItem placeholder='Ihre Emailadresse' name="mail"/>
                            </FormItemWrapper>
                            <FormItemWrapper>
                        <FormHeader>{message}</FormHeader>
                            <MessageArea placeholder='Ihre Nachricht' name="message"/>
                            </FormItemWrapper>
                           
                            <Button type="submit" value="Senden" ></Button>
                           
                            </FormItemsWrapper>
                    </FormWrapper>
                </ContactsWrapper>
            </ContactsContainer>
        </>
    )
}

export default Contact
