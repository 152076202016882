import styled from 'styled-components';

export const Button = styled.input`
border-radius: 1px;
white-space: nowrap;
color: #282828;
font-size: 20px;
cursor: pointer;
background: snow;
transition: all 0.2 ease-in-out;
border: 5px;
outline: thin solid grey;
font-family: Playfair Display, serif;
width: 100%;

text-align:center;
padding: 0.5em;
/* transition: color 1 ease; */

&:hover {
    color: #f0eeea;
    background: #282828;
}

`


export const CallButton = styled.a`
border-radius: 1px;
white-space: nowrap;
text-decoration: none;
color: #282828;
font-size: 20px;
cursor: pointer;
background: snow;
transition: all 0.2 ease-in-out;
border: 5px;
outline: thin solid grey;
font-family: Playfair Display, serif;
text-align:center;
padding: 0.5em;
width: 100%;
display: block;
/* transition: color 1 ease; */

&:hover {
    color: #f0eeea;
    background: #282828;
}
`