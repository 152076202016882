
import React from 'react'
import Slider from "react-slick";
import ImageOne from '../../images/ImageOne.jpg';
import ImageTwo from '../../images/ImageTwo.jpg';
import ImageThree from '../../images/ImageThree.jpg';
import ImageFour from '../../images/ImageFour.jpg';

import './hero.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeroSection = () => {

        var img1 = new Image();
        var img2 = new Image();
        var img3 = new Image();
        var img4 = new Image();
        img1.src = {ImageOne};
        img2.src = {ImageTwo};
        img3.src = {ImageThree};
        img4.src = {ImageFour};


    const settings = {
        dots: true,
        infinite: true,
        // autoplay: true,
        // autoplaySpeed: 10000,
        // swipe: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
      };

    return (
        <>

        <div className="container">
        <Slider {...settings}>
            <div>
            <img className="slide" src={ImageOne}/>
            </div>

            <div>
            <img className="slide" src={ImageTwo}/>
</div>

            <div>
            <img className="slide" src={ImageThree}/>
</div>
            <div>
            <img className="slide" src={ImageFour}/>
</div>
         


        </Slider>
        </div>


        </>
    )
}

export default HeroSection

