import aboutImage from '../../images/teamCardImage.jpg'

export const about = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'seit 1997',
    headLine:'IHR FRISEUR IM WESTEND',
    description:'Seit bereits über 20 Jahren lebt das Haareszeiten Team Ihre Kreativtiät mit Kamm und Schere in der Westendstraße 17 in Wiesbaden aus. In einer einzigartig familienfreundlichen Atmosphäre stehen wir für Sie mit unserem Fachwissen und Gefühl für Haar und Ästhetik immer für Fragen oder individuelle Beratungsgespräche gerne zur Verfügung. Durch regelmäßige Seminarbesuche bleiben wir stets up to date um das Beste aus Ihnen zu machen!' ,
    buttonLabel: 'Termin Buchen',
    imgStart : true,
    img: aboutImage,
    alt: 'utilities',
    dark: true,
    primary: true,
    darkText:false
};